.main_container_faq {
  //   border: 2px solid black;
  height: 170vh;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  flex-wrap: wrap;
}

// headings-
.header_title {
  overflow: visible;
  // width: 296px;
  white-space: nowrap;
  text-align: left;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: rgb(19, 60, 115);
  margin-top: 50px;
}

.header_title2 {
  white-space: nowrap;
  text-align: left;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(19, 60, 115, 1);
}

.faqHeader_heading {
  //   border: 2px solid red;
  height: 100px;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.flex_item_faq1 {
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  color: rgba(19, 60, 115, 1);
}

// .flex_item_faq2 {
//   font-style: normal;
//   font-weight: normal;
//   font-size: 22px;
//   color: rgba(19, 60, 115, 1);
// }

// booking and container-
.bp_container {
  display: flex;
  //   border: 2px solid cyan;
  border-bottom: 2px solid rgb(205, 197, 222);
  height: 100px;
  margin-top: 30px;
  align-items: center;
  background-color: rgba(249, 249, 247, 1);
}

.bp_icon {
  border: 2px dotted black;
  height: 45px;
  width: 59px;
}

.bp_text {
  margin-left: 10px;
  left: 55.33px;
  top: 1.8px;
  overflow: visible;
  width: 296px;
  white-space: nowrap;
  text-align: left;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: rgba(19, 60, 115, 1);
}

// accordian-
.Accordian_Heading {
  white-space: nowrap;
  text-align: left;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(3, 106, 225, 1);
}

.Accordian_SubHeading {
  white-space: nowrap;
  text-align: left;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(27, 156, 93, 1);
}

.Accordian_body {
  text-align: left;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(112, 112, 112, 1);
}

//
#Read_More_ {
  display: flex;
  justify-content: flex-end;
  text-align: left;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(3, 106, 225, 1);
}

.Getting_Started {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bolder !important;
  // font-size: 22px;
  color: rgba(19, 60, 115, 1);
}


.bg-card:hover {
  background-color: rgba(27, 156, 93, 1);
  cursor: pointer;
}

.bg-card:hover * {
  color: white;
  cursor: pointer;
}

.bg-card:hover p {
  color: white;
}

.bg-card p {
  white-space: nowrap;
  text-align: center;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(19, 60, 115, 1);
}

.bg-card:hover * {
  fill: white;
}


.customCarouselItem {
  border: 1px solid #cfcdcd;
  border-radius: 7px;
}

.faq-item-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  text-align: center;
  overflow: hidden;
  transition: all 0.3s ease;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // width: fit-content;
}

.faq-item-desc {
  margin-bottom: 0;
  font-family: Quicksand !important;
  font-weight: 700 !important;
  color: rgba(19, 60, 115, 1);
  overflow: hidden;
  text-overflow: ellipsis;
}

.faq-carousel-container {
  padding-inline-start: 50px;
}

@media (min-width: 1200px) {
  .faq-carousel-container .carousel-inner {
    display: flex;
  }

  .faq-carousel-container .carousel-inner .carousel-item {
    flex: 0 0 20%;
    /* Display 5 items in a row on screens wider than 1200px */
    max-width: 20%;
  }
}

@media (max-width: 1199px) {
  .faq-carousel-container .carousel-inner .carousel-item {
    flex: 0 0 25%;
    /* Display 4 items in a row on screens between 992px and 1199px */
    max-width: 25%;
  }
}

.faq-header .mb-3 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.faq-header_title {
  color: rgb(19, 60, 115);
  font-weight: 800;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.faq-header_title2 {
  color: rgb(19, 60, 115);
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .faq-header_title {
    font-size: 2rem;
  }

  .faq-header_title2 {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .custom-accordion .accordion-button {
    justify-content: flex-start;
    text-align: left;
    padding-left: 40px;
  }

  .custom-accordion .accordion-button::before {
    right: auto;
    left: 0;
    transform: none;
  }

  .custom-accordion .accordion-collapse {
    border-top: none;
  }

  .custom-accordion .accordion-collapse.collapse {
    display: none;
  }

  .custom-accordion .accordion-collapse.collapse.show {
    display: block;
  }

  .custom-accordion .accordion-body {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.booking-header {
  justify-content: flex-start;
  padding: 15px;
  display: flex;
  border-bottom: 2px solid rgb(205, 197, 222);
  height: 100px;
  /* margin-top: 30px; */
  align-items: center;
  background-color: rgb(249, 249, 247);
}

.faq-accodian-header {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.faq-accodian-lable {
  margin-bottom: 0px;
  margin-left: 10px;
  overflow: visible;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  color: rgb(19, 60, 115);
}

.faq-container {
  overflow: visible;
}

.accordion-button {
  justify-content: center !important;
  flex-direction: row-reverse !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  width: auto;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x) !important;
  font-size: 1rem !important;
  color: var(--bs-accordion-btn-color) !important;
  background-color: var(--bs-accordion-btn-bg) !important;
  transition: var(--bs-accordion-transition) !important;
  gap: 10px;
}

.faq-accordion-subheader {
  color: green;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.faq-accordion-header {
  // color: blue;
  font-weight: 500;
}

.faq-accordion-header .active {
  color: green;
  font-weight: 500;
}

.faq-accordion-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.read-more {
  cursor: pointer;
  font-family: Quicksand;
  font-style: normal;
  align-items: end;
  text-decoration: underline;
  font-size: 16px;
  color: rgba(3, 106, 225, 1);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}


.kVtMtA {
  position: absolute !important;
  top: calc(50% - 17.5px) !important;
  height: 35px !important;
  width: 0 !important;
  background: #fff !important;
  border-radius: 50% !important;
  box-shadow: 0 0 5px 0 #0009 !important;
  z-index: 10 !important;
  cursor: pointer !important;
  font-size: 10px !important;
  opacity: 0.6 !important;
  -webkit-transition: opacity 0.25s !important;
  transition: opacity 0.25s !important;
  left: 5px !important;
  right: initial !important;
}

.bhRnqO {
  position: absolute !important;
  top: calc(50% - 17.5px) !important;
  height: 35px !important;
  width: 0 !important;
  background: #fff !important;
  border-radius: 50% !important;
  box-shadow: 0 0 5px 0 #0009 !important;
  z-index: 10 !important;
  cursor: pointer !important;
  font-size: 10px !important;
  opacity: 0.6 !important;
  -webkit-transition: opacity 0.25s !important;
  transition: opacity 0.25s !important;
  left: initial !important;
  right: 5px !important;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: none !important;
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: none;
  --bs-accordion-btn-focus-box-shadow: 0 !important;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
}