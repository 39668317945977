.selected-properties-container {
	display: grid;
	// grid-template-rows: 255px 1fr;
.link18-bold-no-line {
	font-size: 25px;
	height: 25px;
    font-weight: bold;
    text-decoration: none;
	align-items: center;
}
	.selected-properties-title {
		color: #284866;
		font-size: 35px;
		font-weight: 500;
	}
	.selected-properties-sub-title {
		font-size: 22px;
		font-weight: 500;
		text-align: center;
		padding-bottom: 10px;
	}

	.selected-properties-results {
		position: relative;
		padding: 20px 30px;

		.selected-properties-results-buttons {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 15px;
		}
		@media screen and (max-width: 767px) {
			.mobile-button {
			  display: table !important;
			  margin-left: 20px !important;
			  width: 80% !important;
			}
		  }
		.blue-white-button {
			text-align: center;
			width:100%;
			margin-bottom: 15px; /* Add margin between buttons for better spacing */
		  }
		  
		  /* Media query for large devices */
		  @media only screen and (min-width: 760px) {
			.blue-white-button {
			  width: 50%;
			}
		  }

		.selected-properties-boxes {
			grid-row-gap: 30px;
			width: 100%;
			// display: flex;
			// justify-content: space-between;
		}
	}
}
