:global {
  .profile-settings-page-footer {
    z-index: 100;
  }
}

.terms_footer {
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: rgba(40, 42, 53, 0.65);
  box-sizing: border-box;
  a {
    display: inline;
    color: rgba(40, 42, 53, 0.65);
    text-decoration: underline;
    &:hover {
      color: #165093;
    }
  }
  .term-footer-txt {
    font-size: 14px;
  }
}
