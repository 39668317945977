.edit-details-container {
	border-radius: 10px;

	.model-body {
		.edit-details-header {
			display: flex;
			justify-content: center;
			background-color: #FDFDFD;
			padding: 10px 0;
			column-gap: 30px;
			border-radius: 10px 10px 0 0;

			.edit-details-title {
				font-size: 35px;
				font-weight: 500;
				color: #133C73;
			}
		}

		.edit-details-main {
			display: grid;
			grid-template-columns: 1fr 1fr;
			padding: 20px;
			border: 1px solid #E7E7E7;
			border-width: 1px 0;
		}

		.edit-details-footer {
			background-color: #FDFDFD;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-right: 20px;
		}
	}
}

.form-control-2 {
	font-size: 35px;
}