// .favorites-container {

// 	.favorites-results {
// 		padding: 20px;
// 		position: relative;

// 		.favorites-results-buttons {
// 			display: flex;
// 			flex-direction: column;
// 			align-items: center;
// 		}

// 		.favorites-top-panel {
// 			display: flex;
// 			justify-content: space-between;
// 		}

// 		.favorites-main-title {
// 			font-size: 40px;
// 			color: #284866;
// 		}

// 		.favorites-main-subtitle {
// 			font-size: 23px;
// 			color: #284866;
// 		}

// 		.favorites-main-selection-title {
// 			font-size: 20px;
// 			font-weight: 500;
// 			color: #284866;
// 			padding-top: 40px;
// 		}

// 		.favorites-main-selection-icons {
// 			padding-top: 10px;
// 			display: grid;
// 			grid-template-columns: 80px 80px 80px 80px;
// 			align-items: center;
// 			justify-items: center;
// 		}

// 		.favorites-boxes {
// 			grid-row-gap: 30px;
// 			width: 100%;
// 			// display: flex;
// 			// justify-content: space-between;
// 		}
// 	}

// 	.favorites-no-results-container {
// 		background-size: cover;
// 		backdrop-filter: blur(15px);
// 		background-color: hsl(216deg 72% 27% / 50%);

// 		.favorites-no-results-main {
// 			padding-top: 10px;
// 			display: flex;
// 			flex-direction: column;
// 			align-items: center;
// 			color: white;
// 		}
// 	}

// }


.properties-lable {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.favorites-container {
	position: relative;
}

.favorites-header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.image-container {
	height: 245px;
	/* Set the fixed height */
	overflow: hidden;
	/* Hide any overflow beyond this height */
}

.image-container img {
	width: 100%;
	height: auto;
	display: block;
	object-fit: cover;
	/* Maintain aspect ratio and cover the container */
}

.favorite-txt {
	color: #16395C;
	font-weight: 600;
	white-space: nowrap;
}

@media (min-width:769px) {
	.dropdown {
		width: 130px;
	}
}

.back-btn {
	font-weight: 500;
	color: blue;
	display: flex;
	align-items: center;
}