.datagrid-container {
	position: relative;
	overflow-x: auto;
	overflow-y: auto;

	.datagrid-headers-scroll-patch {
		width: 20px;
		height: 50px;
		position: absolute;
	}

	.datagrid-headers-container {
		overflow-y: hidden;
		overflow-x: hidden;
		//width:2500px;

		.datagrid-headers {
			display: grid;
			height: 80px;
			align-items: center;
			justify-content: center;
			user-select: none;
			border-bottom: 1px solid #E7E7E6;

			.datagrid-header {
			
				padding-left: 10px;
				font-size: 25px;
				line-height: 22px;
				font-weight: 600;
				color: #707070;
				display: flex;
				align-items: center;
				height: 100%;
				width: 100%;
			}
		}
	}

	.datagrid-body {
	
		.datagrid-row {
			display: grid;
			height: 76px;
			align-items: center;
			justify-content: center;
			user-select: none;
			border-bottom: 1px solid #F2F2F2;
			color: #707070;
			font-size: 28px;

			.datagrid-cell {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: flex;
				width: 100%;
				height: 100%;
				align-items: center;
				text-transform: capitalize;
			}
		}
	}

	/* define styles for smaller screens */
	@media only screen and (max-width: 600px) {
		.datagrid-headers {
		  display: block;
		}
		.datagrid-header, .datagrid-cell {
		  display: inline-block;
		  width: 100%;
		}
		.datagrid-row {
		  display: block;
		}
		.datagrid-row > div {
		  display: inline-block;
		  width: 100%;
		  white-space: nowrap;
		  overflow: hidden;
		  text-overflow: ellipsis;
		}
	  }
	  
	  
}
