.clients-container {
	background-repeat: repeat-x;

	.clients-main {
		background-color: #FFF;

		.clients-title {
			font-size: 35px;
			font-weight: 500;
			color: #133C73;

		}

		.clients-paging {
			font-size: 15px;
			font-weight: 500;
			color: #133C73;

		}
	}

	.clients-search-container {
		padding: 10px;
		display: flex;
		align-items: center;
		// background-color: rgba(19, 59, 113);
		row-gap: 20px;
		overflow: hidden;

		.clients-search-input {
			border-radius: 5px;
			font-size: 20px !important;
			padding: 10px;
			height: 40px;
			outline: none;
			border: 0;
			margin-right: 20px;
			border-radius: 0.5rem;
		}

		.clients-search-separator {
			margin: 0 20px;
			height: 50px;
			width: 1px;
			border-right: 1px solid white;
		}

		.clients-search-add-client-button {
			display: flex;
			align-items: center;
			color: white;
			font-size: 22px;
			font-weight: 500;
			user-select: none;
			cursor: pointer;
		}
	}
	.test-conatiner{
		padding: 20px !important;
	}
}

.content-hidden {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}