.edit-agency-container {
	border-radius: 10px;

	.edit-agency-header {
		display: flex;
		justify-content: center;
		background-color: #FDFDFD;
		padding: 20px 0;
		column-gap: 10px;
		border-radius: 10px 10px 0 0 ;

		.edit-agency-title {
			font-size: 35px;
			font-weight: 500;
			color: #133C73;
		}
	}

	.edit-agency-main {
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding: 20px;
		border-top: 1px solid #E7E7E7;
		border-bottom: 1px solid #E7E7E7;
	}

	.edit-agency-footer {
		background-color: #FDFDFD;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: 20px;
	}
}

.custom-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	align-items: center;
	justify-content: center;
	z-index: 9999;
  }
  
  .custom-popup.open {
	display: flex;
  }
  
  .custom-popup-content {
	background-color: white;
	padding: 20px;
	border-radius: 10px;
	width: 80%;
	max-width: 600px;
	max-height: 80%;
	overflow-y: auto;
  }
  