.forgot-container {
  display: grid;
  grid-template-columns: 40% 60%;
  height: 100vh;
}

.forgot-pic-left {
  background-image: url("../../assets/desktop/signin-left-image.png");
  background-size: cover;
}

.forgot-main {
  display: grid;
  grid-template-rows: 1fr 50px;
}

.forgot-box-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fdfdfd;
}

.forgot-box-child {
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  left: 0;
  position: relative;
  top: 0;
  text-align: left;
  width: 394px;
}

.reset-password-form {
  max-width: 580px;
  margin-top: 30px;
}

.reset-password-title {
  margin-top: 15px;
  color: rgb(22, 80, 147);
}

.txt-return-sign-in {
  font-weight: 600;
}

.txt-reset-password-contact {
  margin-top: 100px !important;
  font-weight: 500;
  font-size: 15px;
}
