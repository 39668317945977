
.loading-box {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999; 
	background-color: #ffffff; 
	padding: 20px;
	border-radius: 5px; 
	z-index: 9999999;
  }
  
  .loading-image {
	width: 100px; 
	height: 100px; 
	z-index: 9999999; 
  }
// no gradient all color
.spinner {
	margin: auto;
	width: 125px;
	height: 125px;
	border: 12px solid transparent;
	border-top: 12px solid #3498db;
	border-right: 12px solid #e74c3c;
	border-bottom: 12px solid #2ecc71;
	border-left: 12px solid #f1c40f;
	border-radius: 50%;
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  } 
//   Gradient color spinner
// .spinner {
// 	margin: auto;
// 	width: 125px;
// 	height: 125px;
// 	border-radius: 50%;
// 	background: conic-gradient(
// 	  from 0deg,
// 	  #3498db,
// 	  #e74c3c,
// 	  #2ecc71,
// 	  #f1c40f,
// 	  #3498db
// 	);
// 	mask: radial-gradient(transparent 50%, white 50%); /* Creates a ring shape */
// 	animation: spin 1.5s linear infinite;
//   }
  
//   @keyframes spin {
// 	0% {
// 	  transform: rotate(0deg);
// 	}
// 	100% {
// 	  transform: rotate(360deg);
// 	}
//   }
// Pulsating Dot spinner
// .spinner {
// 	margin: auto;
// 	width: 125px;
// 	height: 125px;
// 	background: #3498db;
// 	border-radius: 50%;
// 	box-shadow: 0 0 0 10px rgba(52, 152, 219, 0.2); /* Outer glow */
// 	animation: pulse 1.5s infinite ease-in-out;
//   }
  
//   @keyframes pulse {
// 	0%, 100% {
// 	  transform: scale(1);
// 	  box-shadow: 0 0 0 10px rgba(52, 152, 219, 0.2);
// 	}
// 	10% {
// 	  transform: scale(1.2);
// 	  box-shadow: 0 0 0 15px rgba(52, 152, 219, 0.1);
// 	}
//   }
// 3d cube spinner
// .spinner {
// 	margin: auto;
// 	width: 125px;
// 	height: 125px;
// 	position: relative;
// 	transform-style: preserve-3d;
// 	animation: rotate 2s linear infinite;
//   }
  
//   .spinner div {
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	background: rgba(52, 152, 219, 0.8);
// 	border: 2px solid #3498db;
//   }
  
//   .spinner div:nth-child(1) { transform: rotateY(0deg) translateZ(20px); }
//   .spinner div:nth-child(2) { transform: rotateY(90deg) translateZ(20px); }
//   .spinner div:nth-child(3) { transform: rotateY(180deg) translateZ(20px); }
//   .spinner div:nth-child(4) { transform: rotateY(270deg) translateZ(20px); }
  
//   @keyframes rotate {
// 	0% {
// 	  transform: rotateY(0deg);
// 	}
// 	100% {
// 	  transform: rotateY(360deg);
// 	}
//   }
// infinity loop
// .spinner {
// 	margin:auto;
// 	width: 250px;
// 	height: 125px;
// 	position: relative;
// 	animation: loop 2s linear infinite;
//   }
  
//   .spinner::before,
//   .spinner::after {
// 	content: '';
// 	position: absolute;
// 	width: 250px;
// 	height: 250px;
// 	border: 4px solid #3498db;
// 	border-radius: 50%;
// 	clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
//   }
  
//   .spinner::before {
// 	left: 0;
//   }
  
//   .spinner::after {
// 	right: 0;
// 	transform: rotate(180deg);
//   }
  
//   @keyframes loop {
// 	0% {
// 	  transform: rotate(0deg);
// 	}
// 	100% {
// 	  transform: rotate(360deg);
// 	}
//   }

// wave
// .spinner {
// 	margin: auto;
// 	width: 125px;
// 	height: 125px;
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
//   }
  
//   .spinner div {
// 	width: 25px;
// 	height: 25px;
// 	background: #3b8c24;
// 	border-radius: 50%;
// 	animation: wave 1.2s infinite ease-in-out;
//   }
  
//   .spinner div:nth-child(1) { animation-delay: 0s; }
//   .spinner div:nth-child(2) { animation-delay: 0.2s; }
//   .spinner div:nth-child(3) { animation-delay: 0.4s; }
//   .spinner div:nth-child(4) { animation-delay: 0.6s; }
  
//   @keyframes wave {
// 	0%, 100% {
// 	  transform: translateY(0);
// 	}
// 	50% {
// 	  transform: translateY(-20px);
// 	}
//   }
// circular wave spinner
// .spinner {
// 	margin: auto;
// 	width: 125px;
// 	height: 125px;
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
//   }
  
//   .spinner div {
// 	width: 25px;
// 	height: 25px;
// 	background: #3498db;
// 	// background: #22763d;
// 	border-radius: 50%;
// 	animation: circle-wave 1.2s infinite ease-in-out;
//   }
//   .face.face-1 {
// 	background: #3498db;
//   }
//   .face.face-2 {
// 	background: #34db3c;
//   }
//   .face.face-3 {
// 	background: #db34a9;
//   }
//   .face.face-4 {
// 	background: #db3434;
//   }
//   .spinner div:nth-child(1) { animation-delay: 0s; }
//   .spinner div:nth-child(2) { animation-delay: 0.2s; }
//   .spinner div:nth-child(3) { animation-delay: 0.4s; }
//   .spinner div:nth-child(4) { animation-delay: 0.6s; }
  
//   @keyframes circle-wave {
// 	0%, 100% {
// 	  transform: translateX(0) translateY(0);
// 	}
// 	25% {
// 	  transform: translateX(15px) translateY(-15px); /* Move right and up */
// 	}
// 	50% {
// 	  transform: translateX(0) translateY(-30px); /* Move up */
// 	}
// 	75% {
// 	  transform: translateX(-15px) translateY(-15px); /* Move left and up */
// 	}
//   }
// 1 color spinner
// .spinner {
// 	margin: auto;
// 	width: 125px;
// 	height: 125px;
// 	border-radius: 50%;
// 	background: conic-gradient(
// 	  from 0deg,
// 	/*  rgba(52, 152, 219, 0.2),  Light shade of the color */
// 	/*   #3498db  Main color */
// 	  rgba(28, 117, 53, 0.2), /* Light shade of the color */
// 	  #1c7535 /* Main color */
// 	);
// 	mask: radial-gradient(transparent 50%, white 50%); /* Creates a ring shape */
// 	animation: spin 1s linear infinite;
//   }
  
//   @keyframes spin {
// 	0% {
// 	  transform: rotate(0deg);
// 	}
// 	100% {
// 	  transform: rotate(360deg);
// 	}
//   }
// 4 color spinner
// .spinner {
// 	margin: auto;
// 	width: 125px;
// 	height: 125px;
// 	border-radius: 50%;
// 	border: 10px;
// 	background: conic-gradient(
// 	  from 0deg,
// 	  #3498db,
// 	  #e74c3c,
// 	  #2ecc71,
// 	  #f1c40f,
// 	  #3498db
// 	);
// 	animation: spin 1s linear infinite;
// 	position: relative;
//   }
  
//   .spinner::before {
// 	content: '';
// 	position: absolute;
// 	inset: 12px;
// 	background: white; /* Match the background color of your container */
// 	border-radius: 50%;
//   }
  
//   @keyframes spin {
// 	0% {
// 	  transform: rotate(0deg);
// 	}
// 	100% {
// 	  transform: rotate(360deg);
// 	}
//   }

