.text-area-field-container {
	.text-area-field-label {
		font-size: 18px;
		font-weight: 500;
		color: #707070;
	}

	.text-area-field-input {
		border: 1px solid #ECEDEE;
		border-radius: 6px;
		padding: 4px 10px;
		width: 100%;
		height: 25%;
		// outline: none;
		// resize: none;
	}
}



.text-item-wrapper {
	gap: 3px;
	align-items: flex-end;
	justify-content: flex-start;
}

.txt-edit-icon {
	height: 25px;
	padding-bottom: 5px;
}