.loader {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(155, 155, 155, 0.828);
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: bold;
  }