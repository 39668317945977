// @import "./../../../../Style/base";
@import "./../../../../Style/form";

.input_wrapper {
  position: relative;
  .input_field_wrapper {
    position: relative;
    top: -25px;
    svg {
      position: absolute;
      bottom: 25px;
      right: 12px;
    }
    &.error {
      svg {
        bottom: 23px;
      }
    }
    select {
      width: 100%;
      top: -25px;
    }
     
  }
}

.email_error {
  color: #d9212c;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}

.DropdownInput_error {
  color: #EF4040;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  display: flex;
  align-items: center;
  background: #FEE7E7;
  padding: 5px;
  border-radius: 6px;
}

svg {
  position: static !important;
  top: 0;
  bottom: 0 !important;
  left: 0;
}
