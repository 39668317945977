
.add-template-button {
	border: 2px solid;
    padding: 0px 3px;
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
	color: #036AE1;
	background-color: transparent;
}