.forgot-container {
	display: grid;
	grid-template-columns: 40% 60%;
	height: 100vh;
}

.forgot-pic-left {
	background-image: url("../../assets/desktop/signin-left-image.png");
	background-size: cover;
}

.forgot-main {
	display: grid;
	grid-template-rows: 1fr 50px;
}


.instructions {
	margin-top: 36px;
}

.instructionsData {
	margin-top: 27px;
}

.cta_bottom_box_button_reset {
	margin-top: 10px;
}

.return_to_login_wrapper {
	width: 100%;
	text-align: center;
	margin-top: 18px;
	a {
		font-family: "Quicksand", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		text-align: center;
		&:hover {
			color: #165093;
		}
	}
}
