.selected-properties-dropdown-container {
	position: relative;
	min-width: 500px;
  
	.selected-properties-dropdown-header {
	  background-color: #1B9C5D;
	  color: #FFF;
	  display: flex;
	  height: 36px;
	  justify-content: space-between;
	  border: 1px solid #70707029;
	  border-radius: 5px;
	  font-size: 20px;
	  font-weight: 500;
	  padding: 2px;
	  user-select: none;
	  cursor: pointer;
	}
  
	.selected-properties-dropdown-body {
	  position: absolute; /* Default to absolute */
	  z-index: 1;
	  width: 100%;
	  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	  background: #FFFFFF 0% 0% no-repeat padding-box;
	  box-shadow: 0 3px 3px #00000029;
	  border: 1px solid #A5A1A129;
	  border-radius: 0 0 10px 10px;
  
	  .selected-properties-dropdown-body-rows {
		max-height: 140px;
		overflow-y: auto;
  
		.selected-properties-dropdown-body-row {
		  padding: 0px;
		  background-color: transparent;
		  display: flex;
		  align-items: center;
  
		  .selected-properties-dropdown-body-row-title {
			font-size: 17px;
			font-weight: 500;
			color: #707070;
			padding-left: 5px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		  }
		}
  
		.selected-properties-dropdown-body-row:hover {
		  background-color: #F5F5F2;
		  cursor: pointer;
		  user-select: none;
		}
	  }
  
	  .selected-properties-dropdown-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 8px;
	  }
	}
  }
  
  @media (max-width: 580px) {
	.selected-properties-dropdown-container .selected-properties-dropdown-body {
	  position: relative; /* Change to relative for smaller screens */
	}
  }

  @media (max-width: 1182px) {
	.selected-properties-dropdown-container {
		min-width: 300px;
	}
  }

  @media (min-width: 1183px) and (max-width: 1440px) {
	.selected-properties-dropdown-container {
		min-width: 400px;
	}
  }
  