.sort-by-container {
	position: relative;

	.sort-by-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #70707029;
		border-radius: 5px;
		font-size: 21px;
		font-weight: 600;
		color: #0F2E4B;
		padding: 5px;
		user-select: none;
		cursor: pointer;

		.sort-by-header-box{
			.sort-by-body-row{
				.sort-by-body-title{
					font-size: 14px !important;
					font-weight: 600;
					color: #707070;
					align-content: right;
				}
		
				.sort-by-body-sub-title{
					font-size: 16px !important;
					color: #AFAFAF;
				}
			}			
		}
	}

	.sort-by-body {
		position: absolute;
		z-index: 1;
		width: 100%;
		background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
		background: #FFFFFF 0% 0% no-repeat padding-box;
		box-shadow: 0 3px 3px #00000029;
		border: 1px solid #A5A1A129;

		.sort-by-body-row {
			padding: 5px;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.sort-by-body-title{
				font-size: 16px !important;
				font-weight: 500;
				color: #707070;
				align-content: right;
			}
	
			.sort-by-body-sub-title{
				font-size: 16px !important;
				color: #AFAFAF;
			}
		}

		.sort-by-body-row:hover {
			background-color: #F5F5F2;
			cursor: pointer;
			user-select: none;
		}

		
	}
}

.sort-by-body-sub-title.bold-order {
    font-weight: bold;
}
