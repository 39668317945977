/* .welcome-bg {
	background-image: url("../../assets/desktop/welcome/welcome-bg.png");
	background-size: cover;
	height: 150vh;
} */

.auth-container {
  display: grid;
  grid-template-columns: 40% 60%;
  height: 150vh;
}

.auth-pic-left {
  background-image: url("../../assets/bk_pool.png");
  background-size: cover;
  height: 150vh;
}

.auth-main {
  height: 140vh;
  overflow: hidden;
  display: grid;
  ;
  grid-template-rows: 1fr 50px;
}

.auth-footer {
  border-top: 1px solid #dfdfdf;
  width: calc(140% - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
}

.auth-box-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdfdfd;
}

.auth-box-child {
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  left: 0;
  position: relative;
  top: 0;
  text-align: left;
}
.auth-box-child-mobile {
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  height: 100%;
  padding: 35px;
  left: 0;
  position: relative;
  top: 0;
  text-align: left;
  width: 100%;
}

.forgot-password {
  margin-top: 18px;
  text-align: center;
  width: 100%;
}

.custom-link {
  color: #0d6efd !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.small-text {
  font-size: 20px !important;
}

.image-width {
  width: 25% !important;
}

.login-form {
  max-width: 550px;
  /* margin-top: 120px; */
}

.sign-in-title {
  margin-top: 35px;
  color: #1E3E5D;
  font-weight: 500;
}

.txt-forgot-password {
  font-size: 14px;
}

.sign-up-form {
  max-width: 875px;
  margin-top: 30px;
}

.sign-up-form-field {
  max-width: 95% !important;
}

.sign-up-form-field-phone {
  width: 92% !important;
  margin-left: 5px;
}

.sign-up-form-field-password {
  width: 93% !important;
  margin-left: 5px;
}

/* .EmailInput_input_field_wrapper__mB7f0 {
  width: 96.5% !important;
  margin-left: 1.8% !important;
} */

.DropdownInput_input_wrapper__dgIoH {
  width: 96.5% !important;
  margin-left: 1.8% !important;
}

.NameInput_label_wrapper__IZrmX {
  margin-left: 7px;
}

.EmailInput_label_wrapper__h218U {
  margin-left: 7px;
}

.DropdownInput_label_wrapper__IVvlf {
  margin-left: 7px;
}

.PasswordInput_label_wrapper__q00QC {
  margin-left: 7px;
}

.phone-input-title {
  margin-left: 5px;
}

.sign-up-phone-control {
  width: 96% !important;
  margin-left: 2% !important;
}

@media (max-width: 767px) {
  .NameInput_label_wrapper__IZrmX {
    margin-left: 7px;
  }

  .EmailInput_label_wrapper__h218U {
    margin-left: 7px;
  }

  .DropdownInput_label_wrapper__IVvlf {
    margin-left: 7px;
  }

  .PasswordInput_label_wrapper__q00QC {
    margin-left: 7px;
  }

  .sign-up-phone-control {
    width: 96% !important;
    margin-left: 2% !important;
  }

  .signup-container-mobile {
    width: 100% !important;
  }

  .signup-button-mobile {
    width: 100% !important;
  }

  #talkWithRepresentative {
    margin-top: -20px !important;
  }

  .form-select {
    width: 96.5% !important;
    margin-left: 1.8% !important;
  }

  /* .EmailInput_input_field_wrapper__mB7f0 {
    width: 97% !important;
    margin-left: 1.8% !important;
  } */

  #password {
    width: 100% !important;
    /* margin-left: 1.8% !important; */
  }

  .DropdownInput_input_wrapper__dgIoH {
    width: 100% !important;
    margin: 0 !important;
  }
}

@media screen and (max-width: 700px) {
  .image-width {
    width: 50% !important;
  }
}
@media screen and (min-width: 769px) {
  .sign-up-contianer {
    margin-top: -150px;
  }
}
